import i18n from "i18next";

import { readState } from "@/__main__/app-state.mjs";
import getData from "@/__main__/get-data.mjs";
import { setVolatileKV, showEphemeralNotification } from "@/app/actions.mjs";
import fetchWikiData from "@/game-destiny2/fetches/fetch-wiki-data.mjs";
import BungieApiUnavailableModel from "@/game-destiny2/models/graphql-bungie-api-unavailable.mjs";
import { bungieApiUnavailable } from "@/game-destiny2/utils/api.mjs";
import { devError } from "@/util/dev.mjs";

export default async function fetchData(): Promise<void> {
  try {
    const requiredPromises = [];

    fetchWikiData();

    const { bungieApiUnavailable: apiUnavailable } = await getData(
      bungieApiUnavailable(),
      BungieApiUnavailableModel,
      ["destiny2", "bungieApiUnavailable"],
      { shouldFetchIfPathExists: true },
    );
    if (apiUnavailable) {
      await setVolatileKV("d2ApiUnavailable", true);
      showEphemeralNotification({
        message: i18n.t(
          "bungie:api.maintenance",
          "The Bungie API for Destiny 2 is currently down for maintenance, please check back soon.",
        ),
        severity: "major",
        routes: ["/destiny2"],
        excludedAppVersions: [],
        includedAppVersions: ["any"],
      });
    } else if (readState.volatile.d2ApiUnavailable && !apiUnavailable) {
      await setVolatileKV("d2ApiUnavailable", false);
    }

    await Promise.all(requiredPromises);
  } catch (e) {
    devError("Failed to fetch static data", e);
  }
}
